.appbar {
  padding: auto, 60px; 
  height: 80px;
}

.toolbar {
  margin: auto; 
  width: 100%; 
  height: 100%;
  max-width: 1500px; 
  justify-content: space-between;
}

.nav-button {
  font-size: 12pt;
  width: 90px;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: border-bottom 0.1s ease;
  border-radius: 0px;
  color: white;
}

.nav-button:hover {
  border-bottom: 4px solid;
  cursor: pointer;
}

.menu-container {
  height: 100%;
  background: #111111;
  position: fixed;
  width: 100%;
  left: -100%;
  z-index: 1;
  padding-top: 200px; 
  transition: all 0.5s ease;
} 

.menu-container.active {
  left: 0;
  z-index: 1;
  transition: all 0.5s ease;
}

.menu-button {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  background: #111111;
}

.menu-button:active {
  background-color: white;
  color: black;
  transition: background-color 0.5s ease;
}

.menu-button:hover {
  border: 4px solid;
  transition: border 0.2s ease;
}